import React from "react";
import Layout from "../components/Layout";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/asesoria_en_pensiones.jpeg)`}}>
                        <h1>Asesoría en Pensiones</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Conocemos las necesidades de nuestros clientes en materia de pensiones.
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>Nuestros Servicios consisten en:</h1>
                            <section className="tiles">
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>1.-</span> Cálculo
                                        y Verificación del salario base de cotizacion promedio cotizado en las últimas
                                        250 semanas. Hacemos el analisis semana por semana considerando los topes e
                                        integracion adecuada del salario base de cotizacion.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>2.-</span> Cálculo
                                        y Verificacion de las Semanas Cotizadas
                                        ante el IMSS (en su caso presentación de oficio de reconocimiento de semanas
                                        perdidas antes de 1982).</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>3.-</span> Revisión
                                        de la Documentación Completa a entregar al
                                        IMSS para iniciar tramite de Pension.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>4.-</span> Asesoría
                                        Personalizada en el trámite de la pensión
                                        visita con el cliente a la Clinica del Imss para ingresar los documentos para el
                                        otorgamiento de la Pension. </p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span
                                        style={{fontWeight: 'bold'}}>5.-</span> Acompañar al cliente a la apertura de la
                                        cuenta de pensionado ante el Banco seleccionado.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span
                                        style={{fontWeight: 'bold'}}>6.-</span> Acompañar al cliente a la Subdelegación
                                        correspondiente para la “Elección de Régimen” y entrega de la Cuenta del
                                        pensionado.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>7.-</span> Revisión
                                        de la Resolución de Pensión emitida por el
                                        IMSS para verificar que estén incluidos los meses que duro el tramite de Pension
                                        (Retroactivo) y las Asignaciones Familiares y Aguinaldo proporcional al momento
                                        del tramite.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>8.-</span> Asesoría
                                        Fiscal en el Cálculo de las retenciones
                                        por ISR por Retiro de las cantidadas Máximas de la Afore por conceptos de Sar
                                        92-97, Retiro 97 y Infonavit 92-97. Reducimos el pago de ISR del 20% a una
                                        cantidad menor aplicando exenciones a través de nuestras estrategias fiscales
                                        ahorrandole mucho dinero a nuestros clientes.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span style={{fontWeight: 'bold'}}>9.-</span> Ayuda en
                                        el retiro de la aportaciones del Fondo de
                                        Ahorro del 1972-1992. Revision y Analisis del Valor Presente de las aportaciones
                                        considerando las actualizaciones por Inflacion. Obteniendo más.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span
                                        style={{fontWeight: 'bold'}}>10.-</span> Asesoría para recuperar las
                                        Aportaciones de la Subcuenta de Vivienda INFONAVIT 97.</p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span
                                        style={{fontWeight: 'bold'}}>11.-</span> Asesoría en la contratación de Seguros
                                        de Gastos Médicos para Jubilados y recomendaciones para reducir el impacto
                                        económico de este necesario producto. </p>
                                </article>
                                <article>
                                    <p style={{color: '#000000'}}><span
                                        style={{fontWeight: 'bold'}}>12.-</span> Asesoría en Inversiones del Fondos
                                        Patrimoniales deacuerdo a una evaluación de Aversiónal riesgo y necesidades
                                        futuras.</p>
                                </article>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
